export const DATA = {
  headerTags: [
    'Challenge yourself',
    'Real Fan',
    'Where will you land',
    'Verseus',
  ],
  artistList: [
    'Beyoncé',
    'Drake',
    'Taylor Swift',
    'The Beatles',
    'Michael Jackson',
    'Madonna',
    'Elton John',
    'Rihanna',
    'Ed Sheeran',
    'Adele',
    'Justin Bieber',
    'Kanye West',
    'Lady Gaga',
    'Bruno Mars',
    'Jay-Z',
    'Eminem',
    'Ariana Grande',
    'The Rolling Stones',
    'Coldplay',
    'Katy Perry',
    'Bob Dylan',
    'Prince',
    'David Bowie',
    'Frank Sinatra',
    'Whitney Houston',
    'Shakira',
    'Celine Dion',
    'Mariah Carey',
    'BTS',
    'Billie Eilish',
    'U2',
    'Bruce Springsteen',
    'Garth Brooks',
    'AC/DC',
    'Chovo',
    'Metallica',
    'Pink Floyd',
    'Led Zeppelin',
    'Queen',
    'Bob Marley',
    'Stevie Wonder',
    'Aretha Franklin',
    'Elvis Presley',
    'The Eagles',
    'Bono',
    'Paul McCartney',
    'Eric Clapton',
    'Phil Collins',
    'Freddie Mercury',
    'Jimi Hendrix',
    'Johnny Cash',
    'Dolly Parton',
    'Barbra Streisand',
    'Janet Jackson',
    'Rod Stewart',
    'B.B. King',
    'Ray Charles',
    'Lionel Richie',
    'Diana Ross',
    'Cher',
    'Mick Jagger',
    'Tina Turner',
    'Carlos Santana',
    'Luis Miguel',
    'Juan Gabriel',
    'Vicente Fernández',
    'Shania Twain',
    'Andrea Bocelli',
    'Luciano Pavarotti',
    'Plácido Domingo',
    'Enrique Iglesias',
    'Ricky Martin',
    'Gloria Estefan',
    'Marc Anthony',
    'Julio Iglesias',
    'Selena Quintanilla',
    'Juanes',
    'Daddy Yankee',
    'Maluma',
    'Shakira',
    'Bad Bunny',
  ],
};

export const PAGE_DATA = {
  maxItemsOptions: [1, 10, 30, 50],
  initItemsSize: 10,
};
